import { createContext, useContext, useState } from 'react'

export type BreakdownsVisibility = Record<string, boolean>
export type BreakdownsFilters = Record<string, string[]>
export type FunnelVisibility = boolean
export type FunnelsFilters = string[]

export type FiltersAndVisibility = {
  breakdownsVisibility: BreakdownsVisibility
  breakdownsFilters: BreakdownsFilters
  funnelsVisibility: FunnelVisibility
  funnelsFilters: FunnelsFilters
  actualsVisibility: boolean
  conversionRateVisibility: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BreakdownFiltersContext = createContext<ReturnType<typeof useInit>>(null as any)

const useInit = () => {
  const [breakdownsVisibility, setBreakdownsVisibility] = useState<BreakdownsVisibility>({})
  const [funnelsVisibility, setFunnelsVisibility] = useState<FunnelVisibility>(false)
  const [breakdownsFilters, setBreakdownsFilters] = useState<BreakdownsFilters>({})
  const [funnelsFilters, setFunnelsFilters] = useState<FunnelsFilters>([])
  const [actualsVisibility, setActualsVisibility] = useState<FunnelVisibility>(false)
  const [conversionRateVisibility, setConversionRateVisibility] = useState<FunnelVisibility>(false)

  return {
    breakdownsVisibility,
    setBreakdownsVisibility,
    breakdownsFilters,
    setBreakdownsFilters,
    funnelsVisibility,
    setFunnelsVisibility,
    funnelsFilters,
    setFunnelsFilters,
    actualsVisibility,
    setActualsVisibility,
    conversionRateVisibility,
    setConversionRateVisibility,
  }
}

export function BreakdownFiltersProvider({ children }: { children?: React.ReactNode }) {
  return <BreakdownFiltersContext.Provider value={useInit()}>{children}</BreakdownFiltersContext.Provider>
}

export const useBreakdownFilters = () => {
  return useContext(BreakdownFiltersContext)
}

import { add, endOfYear } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'

import { DemandBreakdown, DemandPlan, RevenueActual, RevenueActualSource } from '../../common/models'
import { isBreakdownFormField } from '../../utils/type-guards'
import { BreakdownFormField, FormValuesProps } from './types'

const currentDate = new Date() // Get the current date
const currentYear = currentDate.getFullYear() // Get the current year
export const firstDayOfYear = new Date(currentYear, 0, 1) // Set the date to the first day of the year
export const lastDayOfYear = endOfYear(new Date(currentYear, 0, 1)) // Set the date to the first day of the year

export const KEY_COORDINATE_LENGTH = uuidv4().length

const DEFAULT_TARGET = 5000000
const BREAKDOWN_ITEMS_IDS = [uuidv4(), uuidv4(), uuidv4()]
const FUNNEL_IDS = [uuidv4(), uuidv4(), uuidv4()]

export const defaultValues: FormValuesProps = {
  name: 'Next Year',
  description: 'Revenue plan for next year',
  startDate: add(firstDayOfYear, { years: 1 }),
  endDate: add(lastDayOfYear, { years: 1 }),
  targetName: 'Revenue',
  targetValue: DEFAULT_TARGET,
  status: 'active',

  yearlySpread: {
    q1: 22,
    q2: 25,
    q3: 19,
    q4: 34,
  },

  breakdowns: [
    {
      uid: uuidv4(),
      name: 'Revenue Type',
      breakdownType: 'primary',
      breakdownItems: [
        {
          uid: BREAKDOWN_ITEMS_IDS[0],
          name: 'Acquisition',
        },
        {
          uid: BREAKDOWN_ITEMS_IDS[1],
          name: 'Expansion - New',
        },
        {
          uid: BREAKDOWN_ITEMS_IDS[2],
          name: 'Channel',
        },
      ],
    },
  ],

  spreads: {
    [BREAKDOWN_ITEMS_IDS[0]]: {
      spreadPercent: 50,
      value: DEFAULT_TARGET * 0.5,
    },
    [BREAKDOWN_ITEMS_IDS[1]]: {
      spreadPercent: 35,
      value: DEFAULT_TARGET * 0.35,
    },
    [BREAKDOWN_ITEMS_IDS[2]]: {
      spreadPercent: 15,
      value: DEFAULT_TARGET * 0.15,
    },
  },

  funnels: [
    {
      name: 'Inbound',
      uid: FUNNEL_IDS[0],
      funnelItems: [
        {
          uid: uuidv4(),
          name: 'Lead',
          description: 'Any name from any inbound channel (not junk)',
        },
        {
          uid: uuidv4(),
          name: 'MQL',
          description: 'Trial/Contact Us Lead + Valid Email + within TAM',
        },
        {
          uid: uuidv4(),
          name: 'SAL',
          description: 'Not junk + within TAM',
        },
        {
          uid: uuidv4(),
          name: 'SQL',
          description: 'Meets ICP + Any two B.A.N.T.',
        },
        {
          uid: uuidv4(),
          name: 'SQO',
          description: '2 BANT + Pain Identified + T < 60 Days',
        },
        {
          uid: uuidv4(),
          name: 'DEAL',
          description: 'Closed Won Inbound Deal',
        },
      ],
    },
    {
      name: 'Outbound',
      uid: FUNNEL_IDS[1],
      funnelItems: [
        {
          uid: uuidv4(),
          name: 'Lead',
          description: 'Sales Generated from within ICP/Customer',
        },
        {
          uid: uuidv4(),
          name: 'SQL',
          description: 'Meets ICP + Any two B.A.N.T.',
        },
        {
          uid: uuidv4(),
          name: 'SQO',
          description: '2 BANT + Pain Identified + T < 60 Days',
        },
        {
          uid: uuidv4(),
          name: 'DEAL',
          description: 'Closed Won Outbound Deal',
        },
      ],
    },
    {
      name: 'Partner',
      uid: FUNNEL_IDS[2],
      funnelItems: [
        {
          uid: uuidv4(),
          name: 'Lead',
          description: 'Partner Referred Account',
        },
        {
          uid: uuidv4(),
          name: 'SQL',
          description: 'Meets ICP + Valid Partner Account + Any two B.A.N.T.',
        },
        {
          uid: uuidv4(),
          name: 'SQO',
          description: '2 BANT + Pain Identified + T < 90 Days',
        },
        {
          uid: uuidv4(),
          name: 'DEAL',
          description: 'Closed Won Partner Deal',
        },
      ],
    },
  ],
  funnelSpreads: {},
  selectedFunnelSpread: '',
}

export function calculateActual(actual?: RevenueActual) {
  if (!actual) {
    return null
  }

  const revenueSources: RevenueActualSource[] = []
  const calculated: {
    marketing?: RevenueActual['marketingSource']
    sales?: RevenueActual['salesSource']
    partner?: RevenueActual['partnerSource']
    all?: RevenueActualSource
  } = {}

  if (actual.marketingSource) {
    calculated.marketing = actual.marketingSource
    const marketingRevenue = actual.marketingSource.stages.find((ras) => ras.name === 'revenue')
    if (marketingRevenue) {
      revenueSources.push(marketingRevenue)
    }
  }

  if (actual.salesSource) {
    calculated.sales = actual.salesSource
    const salesSource = actual.salesSource.stages.find((ras) => ras.name === 'revenue')
    if (salesSource) {
      revenueSources.push(salesSource)
    }
  }

  if (actual.partnerSource) {
    calculated.partner = actual.partnerSource
    const partnerSource = actual.partnerSource.stages.find((ras) => ras.name === 'revenue')
    if (partnerSource) {
      revenueSources.push(partnerSource)
    }
  }

  const sumSourceField = (
    sources: (RevenueActualSource | undefined)[],
    fieldKey: keyof Pick<RevenueActualSource, 'q1' | 'q2' | 'q3' | 'q4' | 'total'>,
  ) => {
    return sources.reduce((accumulated, source) => {
      if (source && source[fieldKey]) {
        return accumulated + (source[fieldKey] as number)
      }

      return accumulated
    }, 0)
  }

  if (calculated.marketing || calculated.sales || calculated.partner) {
    calculated.all = {
      name: 'revenue',
      q1: sumSourceField(revenueSources, 'q1'),
      q2: sumSourceField(revenueSources, 'q2'),
      q3: sumSourceField(revenueSources, 'q3'),
      q4: sumSourceField(revenueSources, 'q4'),
      total: sumSourceField(revenueSources, 'total'),
    }
  }

  return calculated
}

export const splitBreakdowns = (breakdowns: FormValuesProps['breakdowns']) => {
  let primaryBreakdown: BreakdownFormField | undefined
  let secondaryBreakdown: BreakdownFormField | undefined
  let tertiaryBreakdown: BreakdownFormField | undefined

  breakdowns.forEach((breakdown) => {
    if (breakdown.breakdownType === 'primary') {
      primaryBreakdown = breakdown
    } else if (breakdown.breakdownType === 'secondary') {
      secondaryBreakdown = breakdown
    } else if (breakdown.breakdownType === 'tertiary') {
      tertiaryBreakdown = breakdown
    }
  })

  return {
    primaryBreakdown,
    secondaryBreakdown,
    tertiaryBreakdown,
  }
}

export const splitBreakdownsFromDemandPlan = (breakdowns: DemandPlan['breakdowns']) => {
  let primaryBreakdown: DemandBreakdown | undefined
  let secondaryBreakdown: DemandBreakdown | undefined
  let tertiaryBreakdown: DemandBreakdown | undefined

  breakdowns.forEach((breakdown) => {
    if (breakdown.breakdownType === 'primary') {
      primaryBreakdown = breakdown
    } else if (breakdown.breakdownType === 'secondary') {
      secondaryBreakdown = breakdown
    } else if (breakdown.breakdownType === 'tertiary') {
      tertiaryBreakdown = breakdown
    }
  })

  return {
    primaryBreakdown,
    secondaryBreakdown,
    tertiaryBreakdown,
  }
}

export const funnelSelectionToBreakdowns = (funnelSelection: string, breakdowns: BreakdownFormField[]) => {
  const selectedBreakdowns: ('P' | 'S' | 'T')[] = funnelSelection.split('x') as ('P' | 'S' | 'T')[]
  const { primaryBreakdown, secondaryBreakdown, tertiaryBreakdown } = splitBreakdowns(breakdowns)

  const mapping = {
    P: primaryBreakdown,
    S: secondaryBreakdown,
    T: tertiaryBreakdown,
  }

  return selectedBreakdowns.map((s) => mapping[s]).filter(isBreakdownFormField)
}

export const forEachItemCombination = (breakdowns: BreakdownFormField[], iterator: (key: string) => void) => {
  // Helper function to recursively iterate and build combinations
  function generateCombinations(index: number, currentCombination: string): void {
    // Base case: If the current index is equal to the input length,
    // we've reached the end and can call the callback with the current combination.
    if (index === breakdowns.length) {
      iterator(currentCombination)
      return
    }

    // Recursive case: Iterate over the items in the current index
    const currentItemArray = breakdowns[index].breakdownItems
    currentItemArray.forEach((item) => {
      // For each item, add its id to the current combination and recurse
      generateCombinations(index + 1, currentCombination + item.uid)
    })
  }

  // Start the recursion with the first array and an empty string for the combination
  generateCombinations(0, '')
}

import {
    AuthenticationDetails,
    CognitoRefreshToken,
    CognitoUser,
    CognitoUserPool,
    CognitoUserSession
} from 'amazon-cognito-identity-js'
import { ReactNode, createContext, useCallback, useContext, useEffect, useReducer } from 'react'
import { useMixpanel } from 'react-mixpanel-browser'

import mixpanelEvents from 'common/helpers/mixpanelEvents'

import { jwtDecode } from 'jwt-decode'
import { ApiService } from '../common/helpers/fetch'

enum Types {
  auth = 'AUTHENTICATE',
  logout = 'LOGOUT',
}

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined ? { type: Key } : { type: Key; payload: M[Key] }
}

export type AuthUser = null | Record<string, any>

export type AuthState = {
  isAuthenticated: boolean
  isInitialized: boolean
  user: AuthUser
}

export type AWSCognitoContextType = {
  isAuthenticated: boolean
  isInitialized: boolean
  user: AuthUser
  method: 'cognito'
  login: (email: string, password: string) => Promise<unknown>
  logout: VoidFunction
  forgotPassword: (email: string) => Promise<unknown>
  confirmPassword: (verificationCode: string, email: string, newPassword: string) => Promise<unknown>
}

type AwsAuthPayload = {
  [Types.auth]: {
    isAuthenticated: boolean
    user: AuthUser
  }
  [Types.logout]: undefined
}

type AwsActions = ActionMap<AwsAuthPayload>[keyof ActionMap<AwsAuthPayload>]

type AuthProviderProps = {
  children: ReactNode
}

const UserPool = new CognitoUserPool({
  UserPoolId: import.meta.env.VITE_COGNITO_USERPOOL_ID,
  ClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
})

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
}

const reducer = (state: AuthState, action: AwsActions) => {
  if (action.type === 'AUTHENTICATE') {
    const { isAuthenticated, user } = action.payload
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    }
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    }
  }
  return state
}

export const AuthContext = createContext<AWSCognitoContextType | null>(null)

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const mixpanel = useMixpanel()

  const getUserAttributes = useCallback(
    (currentUser: CognitoUser): Record<string, any> =>
      new Promise((resolve, reject) => {
        currentUser.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err)
          } else {
            const results: Record<string, any> = {}

            attributes?.forEach((attribute) => {
              results[attribute.Name] = attribute.Value
            })
            resolve(results)
          }
        })
      }),
    [],
  )

  const updateSession = useCallback(async (user: CognitoUser, session: CognitoUserSession) => {
    const attributes = await getUserAttributes(user)
    const accessToken = session.getAccessToken().getJwtToken()
    const refreshToken = session.getRefreshToken().getToken()

    const { iat, exp } = jwtDecode(accessToken)
    if (iat && exp) {
      const expiresInSeconds = exp - iat
      setTimeout(
        async () => {
          await refreshSession(refreshToken)
        },
        expiresInSeconds * 0.3 * 1000,
      )
    }

    if (mixpanel) {
      mixpanel.identify(attributes.sub)
      mixpanel.track(mixpanelEvents.session)
      mixpanel.people.set({
        Email: attributes.email,
        name: attributes.name,
        family_name: attributes.family_name,
      })
    }

    ApiService.setConfig({
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Hel-Refresh-Token': refreshToken,
      },
    })

    dispatch({
      type: Types.auth,
      payload: { isAuthenticated: true, user: { ...attributes } },
    })

    return {
      user,
      session,
      headers: { Authorization: accessToken },
    }
  }, [])

  const refreshSession = useCallback(async (refreshToken: string) => {
    const user = UserPool.getCurrentUser()
    if (user) {
      user.refreshSession(
        new CognitoRefreshToken({ RefreshToken: refreshToken }),
        (err: Error | null, session: CognitoUserSession | null) => {
          if (err) {
            throw err
          }
          if (session) {
            updateSession(user, session)
            .then(() => {
                console.log('refreshed session')
            })
            .catch(e => {
                console.error('error refreshing session', e)
            })
          }
        },
      )
    }
  }, [])

  const getSession = useCallback(() => {
    const user = UserPool.getCurrentUser()
    if (user) {
      user.getSession(async (err: Error | null, session: CognitoUserSession | null) => {
        if (err) {
          throw err
        }
        if (session) {
          return updateSession(user, session)
        }
      })
    } else {
      dispatch({
        type: Types.auth,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      })
    }
  }, [])

  const initial = useCallback(async () => {
    try {
      await getSession()
    } catch {
      dispatch({
        type: Types.auth,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      })
    }
  }, [])

  useEffect(() => {
    initial()
  }, [])

  const login = useCallback(
    (email: string, password: string) =>
      new Promise((resolve, reject) => {
        const user = new CognitoUser({ Username: email, Pool: UserPool })
        const authDetails = new AuthenticationDetails({ Username: email, Password: password })
        user.authenticateUser(authDetails, {
          onSuccess: (data) => {
            getSession()
            resolve(data)
            if (mixpanel) {
              mixpanel.track(mixpanelEvents.login)
            }
          },
          onFailure: (err) => {
            reject(err)
          },
          newPasswordRequired: function (userAttributes) {
            user.completeNewPasswordChallenge(password, userAttributes, {
              onSuccess(data) {
                getSession()
                resolve(data)
                console.info('Completed the new password challenge!')
              },
              onFailure(e) {
                console.info('Failed to complete the new password challenge')
                console.error(e)
              },
            })
          },
        })
      }),
    [getSession],
  )

  const forgotPassword = (email: string) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    })
    return new Promise((resolve) => {
      user.forgotPassword({
        onSuccess: (data) => {
          resolve(data)
        },
        onFailure: (err) => {
          alert(err.message || JSON.stringify(err))
        },
      })
    })
  }

  const confirmPassword = (verificationCode: string, email: string, newPassword: string) =>
    new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
      })
      user.confirmPassword(verificationCode, newPassword, {
        onSuccess(data) {
          console.info('Password confirmed!')
          resolve(data)
        },
        onFailure(err) {
          console.error('err', err)
          reject(err)
        },
      })
    })

  const logout = () => {
    const user = UserPool.getCurrentUser()
    if (user) {
      user.signOut()
      ApiService.setConfig({
        headers: {},
      })
      dispatch({ type: Types.logout })
      if (mixpanel) {
        mixpanel.track(mixpanelEvents.logout)
        mixpanel.reset()
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'cognito',
        user: state.user,
        login,
        logout,
        forgotPassword,
        confirmPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)

  if (!context) throw new Error('Auth context must be use inside AuthProvider')

  return context
}

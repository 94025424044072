import { DemandBreakdown } from 'common/models'
import { BreakdownFormField } from 'pages/demand-plan/types'

export const isString = (x: unknown): x is string => {
  return !!x
}

export const isBreakdownFormField = (x: unknown): x is BreakdownFormField => {
  return !!x
}

export const isBreakdown = (x: unknown): x is DemandBreakdown => {
  return !!x
}

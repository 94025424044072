import { wrapUseRoutes } from '@sentry/react'
import ConfirmEmail from 'pages/auth/ConfirmEmail'
import ConfirmInvite from 'pages/auth/ConfirmInvite'
import { BreakdownFiltersProvider } from 'pages/demand-plan/components/BreakdownFilters/BreakdownFiltersProvider'
import { ElementType, lazy, Suspense } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { Loader } from '../common/components/Loader'
import { AuthGuard, GuestGuard } from '../guards'

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  )
}

const Login = Loadable(lazy(() => import('../pages/auth/Login')))
const Register = Loadable(lazy(() => import('../pages/auth/Register')))
const NewDemandPlan = Loadable(lazy(() => import('../pages/demand-plan/containers/NewDemandPlan')))
const EditDemandPlan = Loadable(lazy(() => import('../pages/demand-plan/containers/EditDemandPlan')))
const DemandPlanOutput = Loadable(lazy(() => import('../pages/demand-plan/containers/DemandPlanOutput')))
const ForgotPassword = Loadable(lazy(() => import('../pages/auth/ForgotPassword')))
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')))
const HomePage = Loadable(lazy(() => import('../pages/home')))
const SettingsPage = Loadable(lazy(() => import('../pages/settings')))
const AccountSettings = Loadable(lazy(() => import('../pages/settings/containers/AccountSettings')))
const UserSettings = Loadable(lazy(() => import('../pages/settings/containers/UserSettings')))

const useSentryRoutes = wrapUseRoutes(useRoutes)

const Router = () => {
  return useSentryRoutes([
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: 'forgot-password',
      element: (
        <GuestGuard>
          <ForgotPassword />
        </GuestGuard>
      ),
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      ),
    },
    {
      path: 'auth/confirm/email',
      element: (
        <GuestGuard>
          <ConfirmEmail />
        </GuestGuard>
      ),
    },
    {
      path: 'auth/confirm/invite',
      element: (
        <GuestGuard>
          <ConfirmInvite />
        </GuestGuard>
      ),
    },
    {
      path: 'auth/reset-password',
      element: (
        <GuestGuard>
          <ResetPassword />
        </GuestGuard>
      ),
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <HomePage />
        </AuthGuard>
      ),
    },
    {
      path: '/new-revenue-plan',
      element: (
        <AuthGuard>
          <NewDemandPlan />
        </AuthGuard>
      ),
    },
    {
      path: '/revenue/:id',
      element: (
        <AuthGuard>
          <BreakdownFiltersProvider>
            <DemandPlanOutput />
          </BreakdownFiltersProvider>
        </AuthGuard>
      ),
    },
    {
      path: '/revenue/:id/edit',
      element: (
        <AuthGuard>
          <EditDemandPlan />
        </AuthGuard>
      ),
    },
    {
      path: '/settings',
      children: [
        { index: true, element: <Navigate to="user" /> },
        { path: 'account', element: <AccountSettings /> },
        { path: 'user', element: <UserSettings /> },
      ],
      element: (
        <AuthGuard>
          <SettingsPage />
        </AuthGuard>
      ),
    },
    { path: '*', element: <div>404</div> },
  ])
}

const App = () => <Router />

export default App
